<template>
    <v-container fluid class="home-container">
        <page-filters-section top-img="/img/staking-map/staking-map-header.png">
            <h1 class="main-title">Staking Map</h1>
            <div class="header-text">
                All staked SCRL can be taken back anytime by clicking the "Reduce" button.
                Each {{ 100000 | localFormatNumber }} gives one Work Point. Every Shrine has a separate Work Point's
                <img class="work-icon" src="/img/staking-map/work.svg"/> collection.
                These points are used to get rewards.
                The stake amount always remains {{ 100000 | localFormatNumber }} SCRL,
                and the refundable amount of SCRL corresponds to the staked amount.
                <v-btn x-small target="_blank" class="mx-2"
                       href="https://medium.com/@wizarre/a-complete-guide-to-wizarres-new-staking-feature-c1a84f0a1bc7">
                    Read more
                </v-btn>
            </div>
<!--            <div id="actions" v-if="isWalletConnected"-->
<!--                 class="d-flex flex-column flex-md-row justify-space-between pos-relative"-->
<!--            >-->
<!--                <div class="input-approve-create-box d-flex flex-row pos-relative justify-center">-->
<!--                    <input-amount-->
<!--                        :init-value="stakeAmount"-->
<!--                        :max-value="maxStakesAmountWithinBalance"-->
<!--                        v-on:valueChanged="valueChanged"-->
<!--                        label="Stakes amount"-->
<!--                    ></input-amount>-->
<!--                    <v-btn x-small-->
<!--                           class="input-approve-max-btn"-->
<!--                           @click="stakeAmount = Number(maxStakesAmountWithinBalance)"-->
<!--                    >-->
<!--                        Max-->
<!--                    </v-btn>-->

<!--                    <price class="ml-4" style="margin-top: -16px" :price="stakesPrice"></price>-->
<!--                </div>-->

<!--                <div class="btn-approve-create-box d-flex flex-row justify-center mt-4 mt-md-0">-->
<!--                    <v-btn class="m-0"-->
<!--                           :loading="loadingData"-->
<!--                           :disabled="!isPriceWithinAllowance"-->
<!--                           @click="approveStake()"-->
<!--                    >-->
<!--                        1. Approve-->
<!--                    </v-btn>-->

<!--                    <v-btn class="m-0 ml-2"-->
<!--                           :loading="loadingData"-->
<!--                           :disabled="isPriceWithinAllowance || stakeAmount <= 0 || isPriceWithinUserBalance"-->
<!--                           color="primary"-->
<!--                           @click="createStake()"-->
<!--                    >-->
<!--                        <span class="hidden-sm-and-down">2. Create stake</span>-->
<!--                        <span class="hidden-md-and-up">2. Create</span>-->
<!--                        <img class="work-icon ml-2" src="/img/staking-map/work.svg"/> +{{ stakeAmount }}-->
<!--                    </v-btn>-->
<!--                </div>-->
<!--            </div>-->
        </page-filters-section>

        <div class="spacer"/>

        <div class="page-content mx-auto">
            <v-chip class="next-map-timer" color="secondary" v-if="displayNewMapTimestamp">
                Next map starts in&nbsp;<timer :deadline-timestamp="nextMap.startTimestamp"></timer>
            </v-chip>
            <div class="d-flex flex-column flex-sm-row align-center justify-center justify-md-end">
                <v-menu
                    v-model="archive"
                    absolute
                    bottom
                    rounded
                    transition="fade-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :class="{selected: displayedMapId < latestDisplayedMapId}"
                            class="map-selector-btn"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            <img src="/img/staking-map/tornado.png"/>
                            Archive
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list rounded>
                            <v-list-item>
                                <v-btn :class="{selected: displayedMapId === 0}" class="mx-1 map-archive-btn"
                                       color="brown darken-1"
                                       @click="displayedMapId = 0">
                                    <img src="/img/staking-map/biomes/desert/icon.svg"/>
                                    <span>Desert</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn :class="{selected: displayedMapId === 1}" class="mx-1 map-archive-btn"
                                       color="teal darken-3"
                                       @click="displayedMapId = 1">
                                    <img src="/img/staking-map/biomes/shrooms/icon.svg"/>
                                    <span>Forest</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn :class="{selected: displayedMapId === 2}" class="mx-1 map-archive-btn"
                                       color="#727272"
                                       @click="displayedMapId = 2">
                                    <img src="/img/staking-map/biomes/spooky/icon.svg"/>
                                    <span>Spooky</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn :class="{selected: displayedMapId === 3}" class="mx-1 map-archive-btn"
                                       color="#D6749BFF"
                                       @click="displayedMapId = 3">
                                    <img src="/img/staking-map/biomes/clouds/icon.svg"/>
                                    <span>Clouds</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn :class="{selected: displayedMapId === 4}"
                                       class="mx-1 map-archive-btn"
                                       color="#194189"
                                       @click="displayedMapId = 4">
                                    <img src="/img/staking-map/biomes/shores/icon.svg"/>
                                    <span>Shores</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn :class="{selected: displayedMapId === 5}"
                                       class="mx-1 map-archive-btn"
                                       color="#3E1907A0"
                                       @click="displayedMapId = 5">
                                    <img src="/img/staking-map/biomes/volcano/icon.svg"/>
                                    <span>Volcano</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn
                                    :class="{selected: displayedMapId === 6}"
                                    class="mx-1 map-archive-btn"
                                    color="#e4b986"
                                    @click="displayedMapId = 6">
                                    <img src="/img/staking-map/biomes/sweeties/icon.svg"/>
                                    <span>Sweeties</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn :class="{selected: displayedMapId === 7}"
                                       class="mx-1 map-archive-btn"
                                       color="brown darken-1"
                                       @click="displayedMapId = 7">
                                    <img src="/img/staking-map/biomes/desert/icon.svg"/>
                                    <span>Desert II</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="isThisMapArchived(mapTimestamps.find((map)=>map.id===9).startTimestamp)">
                                <v-btn :class="{selected: displayedMapId === mapTimestamps.find((map)=>map.id===8).id}"
                                       class="mx-1 map-archive-btn"
                                       color="teal darken-3"
                                       @click="displayedMapId = mapTimestamps.find((map)=>map.id===8).id">
                                    <img src="/img/staking-map/biomes/shrooms/icon.svg"/>
                                    <span>Forest II</span>
                                </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="isThisMapArchived(mapTimestamps.find((map)=>map.id===10).startTimestamp)">
                                <v-btn :class="{selected: displayedMapId === mapTimestamps.find((map)=>map.id===9).id}"
                                       class="mx-1 map-archive-btn"
                                       color="#727272"
                                       @click="displayedMapId = mapTimestamps.find((map)=>map.id===9).id">
                                    <img src="/img/staking-map/biomes/spooky/icon.svg"/>
                                    <span>Spooky II</span>
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
                <v-btn
                    :class="{selected: displayedMapId === 10}"
                    class="mx-4 map-selector-btn current-map"
                    color="#727272"
                    @click="displayedMapId = 10">
                    <span>Last Map</span>
                </v-btn>
            </div>
            <div class="stake-amount-total text-left mt-6">
                <h3 class="mt-4 ml-2 mb-2">Total</h3>
                <price class="ml-4 mt-2" :price="sumAllStakesAmount * singleStakeTokenValue"></price>
                <br/>
                <div class="work-points">
                    <img src="/img/staking-map/work.svg" class="ml-4" style="height: 30px"/>
                    <span class="ml-2">{{ sumAllStakesAmount | localFormatNumber }}</span>
                </div>
            </div>
            <v-fade-transition hide-on-leave>
                <v-card v-if="!displayedMapId && displayedMapId !== 0 || archive">
                    <v-skeleton-loader
                        class="mx-auto pt-16 pb-4"
                        height="600"
                        type="image"
                    >
                    </v-skeleton-loader>
                </v-card>
                <div v-else>
                    <biome1-desert
                        v-if="displayedMapId===0"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('desert')"
                    />
                    <biome2-shrooms
                        v-if="displayedMapId===1"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('shrooms')"
                    />
                    <biome3-spooky
                        v-if="displayedMapId===2"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('spooky')"
                    />
                    <biome4-clouds
                        v-if="displayedMapId===3"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('clouds')"
                    />
                    <biome5-shores
                        v-if="displayedMapId===4"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('shores')"
                    />
                    <biome6-volcano
                        v-if="displayedMapId===5"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('volcano')"
                    />
                    <biome7-sweeties
                        v-if="displayedMapId===6"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('sweeties')"
                    />
                    <biome1-cycle2-desert
                        v-if="displayedMapId===7"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('desert_c2')"
                    />
                    <biome2-cycle2-desert
                        v-if="displayedMapId===8"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('shrooms_c2')"
                    />
                    <biome3-cycle2-spooky
                        v-if="displayedMapId===9"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('spooky_c2')"
                    />
                    <biome4-cycle2-clouds
                        v-if="displayedMapId===10"
                        :pawns="pawns"
                        :shrineDetails="getShrineDetailsByName('clouds_c2')"
                    />
                </div>
            </v-fade-transition>
            <v-row>
                <staking-table
                    v-if="isWalletConnected"
                    :stakes="stakes"
                    :shrinesDetails="getShrinesDetails"
                ></staking-table>
            </v-row>
        </div>
        <v-menu v-if="DEV_createdAt">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="DEV_createdAt"
                    label="DEV startAt"
                    light
                    prepend-icon="mdi-calendar"
                    readonly
                    style="position: absolute;left: 0;top:0px;background: pink;color:#000;overflow: hidden;"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="DEV_createdAt"
                no-title
                scrollable
            >
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="$refs.menu.save(DEV_createdAt)"
                >
                    OK
                </v-btn>
            </v-date-picker>
        </v-menu>
    </v-container>
</template>

<script>
import ShrinesDetails from "../settings/shrine-details.json";
import PageFiltersSection from "../components/page-filters-section";
import InputAmount from "../components/input-amount";
import Price from "../components/price";
import RewardShrine from "../components/staking-map/reward-shrine";
import DialogBox from "../components/dialog-box";
import StakingTable from "../components/staking-map/staking-table";
import {stakingMapServiceVue} from "../services/stakingMapService.vue";
import Biome1Desert from "../components/staking-map/biome-1-desert";
import Biome2Shrooms from "../components/staking-map/biome-2-shrooms";
import Biome3Spooky from "../components/staking-map/biome-3-spooky";
import {StakingMapV2Contract} from "@/services/contracts/stakingMapV2Contract";
import Timer from "../components/timer";
import * as timeHelper from "../helpers/timeHelper";
import * as envHelper from "../helpers/envHelper";
import {UserWalletError} from "@/errors/WalletErrors";
import Biome4Clouds from "@/components/staking-map/biome-4-clouds";
import Biome5Shores from "@/components/staking-map/biome-5-shores";
import Biome6Volcano from "@/components/staking-map/biome-6-volcano";
import Biome7Sweeties from "@/components/staking-map/biome-7-sweeties";
import Biome1Cycle2Desert from "@/components/staking-map/biome-1-cycle-2-desert";
import Biome2Cycle2Desert from "@/components/staking-map/biome-2-cycle-2-desert";
import Biome3Cycle2Spooky from "@/components/staking-map/biome-3-cycle-2-spooky";
import Biome4Cycle2Clouds from "@/components/staking-map/biome-4-cycle-2-clouds";

const SINGLE_STAKE_TOKEN_VALUE = 100000;
//TODO: if all stakes removed - remain path and open all shrines on the way (maxDay remember)

export default {
    components: {
        Biome4Cycle2Clouds,
        Biome3Cycle2Spooky,
        Biome2Cycle2Desert,
        Biome1Cycle2Desert,
        Biome7Sweeties,
        Biome6Volcano,
        Biome5Shores,
        Biome4Clouds,
        Biome3Spooky,
        Timer,
        Biome1Desert,
        Biome2Shrooms,
        StakingTable,
        DialogBox, RewardShrine, Price, InputAmount, PageFiltersSection
    },
    data: function () {
        return {
            archive: false,
            routeName: null,
            stakeAmount: 1,
            allowance: 0,
            stakingMapContract: null,
            stakes: [],
            loadingData: false,
            loadingMap: false,
            displayedMapId: null,
            mapTimestamps: [
                {id: 7, startTimestamp: 1681394400 + (360 - 90) * 24 * 60 * 60},
                {id: 8, startTimestamp: 1681394400 + 360 * 24 * 60 * 60}, //forest2
                {id: 9, startTimestamp: 1681394400 + (360 + 90) * 24 * 60 * 60}, //spooky2
                {id: 10, startTimestamp: 1681394400 + (360 + 2 * 90) * 24 * 60 * 60}, //clouds2
                {id: 11, startTimestamp: 1681394400 + (360 + 3 * 90) * 24 * 60 * 60}, //shores2
                {id: 12, startTimestamp: 1681394400 + (360 + 4 * 90) * 24 * 60 * 60}, //volcano2
            ],
            DEV_createdAt: envHelper.isStakingMapDEV() ? (new Date()).toISOString().split('T')[0] : null,
        };
    },
    mounted: function () {
        this.routeName = this.$route.name;

        if (this.isWalletConnected) {
            this.fetchMapContractData(this.web3);
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.fetchMapContractData(this.web3);
            });
        }

        setTimeout(() => {
                if (null !== localStorage.getItem('StakingMapTabId')) {
                    this.displayedMapId = Number(localStorage.getItem('StakingMapTabId'));
                } else {
                    this.displayedMapId = this.latestDisplayedMapId;
                }
            },
            500
        )
    },
    methods: {
        valueChanged(newStakesAmount) {
            this.stakeAmount = BigInt(newStakesAmount.value);
        },
        fetchMapContractData(web3) {
            this.stakingMapContract = new StakingMapV2Contract(web3);

            this.fetchAllowance();
            this.loadingMap = true;

            // Staking map
            this.stakingMapContract.getMyActiveStakesForStakingMap()
                .then((res) => {
                    if (this.$route.name !== this.routeName) {
                        return;
                    }
                    let tmpStakes = [];
                    for (let i = 0; i < res._indexes.length; i++) {
                        if (0 == parseInt(res._stakes[i].endAt)) {
                            const content = {
                                id: Number(res._indexes[i]),
                                createdAt: Number(res._stakes[i].createdAt),
                                endAt: Number(res._stakes[i].endAt),
                                age: Number(res._stakes[i].age),
                                stakesAmount: BigInt(res._stakes[i].stakesAmount),
                                startPos: Number(res._stakes[i].startPos),
                                currentPos: Number(res._stakes[i].currentPos),
                                bonusPeriods: res._stakes[i].bonusPeriods.map(s => BigInt(s)),
                                bonusPercents: res._stakes[i].bonusPercents.map(s => BigInt(s)),
                                mapVersion: Number(res._stakes[i].mapVersion),
                            };
                            tmpStakes.push(content);
                        }
                    }
                    this.stakes = tmpStakes;
                })
                .catch((error) => {
                    console.error(error);
                    alert('An error occurred when loading Map contract. Please try again later or contact Wizarre support.');
                })
                .finally(() => {
                    this.loadingMap = false;
                });
        },
        approveStake() {
            this.loadingData = true;
            this.stakingMapContract.approveForStakingMap()
                .then(() => {
                    this.fetchAllowance();
                    this.steps = 2;
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                        this.flashMessage.show({
                            status: 'error',
                            message: 'An error occurred. Please try again later or contact Wizarre support.'
                        });
                    }
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },
        fetchAllowance() {
            this.loadingData = true;
            this.stakingMapContract.tokenAllowanceForStakingMap()
                .then((allowance) => {
                    this.allowance = allowance;
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },
        createStake() {
            this.loadingData = true;
            this.$gtm.trackEvent({
                'event': 'GA-StakingMap-CreateStake-Started',
            });
            const createdAt = timeHelper.dateToUtcMicroTimestamp(new Date(this.DEV_createdAt));
            this.stakingMapContract.stakeStakingMap(this.stakeAmount, createdAt)
                .then(() => {
                    this.$store.dispatch('updateWeb3Balance');
                    this.fetchMapContractData(this.web3);
                    this.$gtm.trackEvent({
                        'event': 'GA-StakingMap-CreateStake-Done',
                    });
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                        this.flashMessage.show({
                            status: 'error',
                            message: 'An error occurred. Please try again later or contact Wizarre support.'
                        });
                    }
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },
        getShrineDetailsByName(name) {
            return ShrinesDetails[name] || {};
        },
        isThisMapArchived(mapEndTimestamp) {
            return mapEndTimestamp < this.currentTimeInSeconds;
        }
    },
    watch: {
        displayedMapId(newId, oldId) {
            if (newId !== null && newId !== undefined && newId >= 0) {
                localStorage.setItem("StakingMapTabId", newId)
            }
        }
    },
    computed: {
        currentTimeInSeconds() {
            // return parseInt(timeHelper.nowInSeconds() + 3600 * 24 * 20); //TODO: debug
            return parseInt(timeHelper.nowInSeconds());
        },
        getShrinesDetails() {
            return ShrinesDetails;
        },
        latestDisplayedMapId() {
            return this.currentMap.id;
        },
        /** @return {id: number, timestamp} */
        currentMap() {
            return this.mapTimestamps
                .filter((mapStart) => mapStart.startTimestamp < this.currentTimeInSeconds)
                .at(-1)
        },
        /** @return {id: number, timestamp} */
        nextMap() {
            return this.mapTimestamps
                .filter((mapStart) => mapStart.startTimestamp > this.currentTimeInSeconds)
                .at(0);
        },
        displayNewMapTimestamp() {
            return this.currentTimeInSeconds < this.nextMap.startTimestamp && this.nextMap.startTimestamp - this.currentTimeInSeconds < 3600 * 24 * 10; // display when less than 10 days remaining
        },
        pawns() {
            let pawns = [];
            for (const stake of this.stakes) {
                const exactSize = Number(
                    stake.stakesAmount
                    * stake.bonusPercents.map(s => BigInt(s) / BigInt(100)).reduce((a, b) => BigInt(a) + BigInt(b), BigInt(1))
                )
                const size = Math.floor(exactSize).toLocaleString();

                const day = stakingMapServiceVue.calculateDays(stake);
                let activeMap = 0;
                let offsetDayOnActiveMap = day;
                for (let i = 0; i < stake.bonusPeriods.length; i++) {
                    if (Number(stake.currentPos) > Number(stake.bonusPeriods[i])) {
                        activeMap = i + 1;
                        offsetDayOnActiveMap = day - stakingMapServiceVue.calculatePeriodToDays(Number(stake.bonusPeriods[i]));
                    }
                }
                pawns.push({
                    id: stake.id,
                    fullId: 'pawn-' + stake.id,
                    size: size,
                    img: stakingMapServiceVue.pawnImage(size),
                    imgMini: stakingMapServiceVue.pawnImage(size, true),
                    day,
                    activeMap,
                    offsetDayOnActiveMap,
                    activeBonuses: stake.bonusPercents.map(v => !!Number(v))
                });
            }
            pawns = pawns.sort((a, b) => (a.size > b.size) ? 1 : -1); //sort to show small on top

            return pawns
        },
        sumAllStakesAmount() {
            return this.stakes.reduce((a, b) => parseInt(a) + parseInt(b.stakesAmount), 0);
        },
        isDebugEnv() {
            return 'development' === process.env.NODE_ENV;
        },
        singleStakeTokenValue() {
            return SINGLE_STAKE_TOKEN_VALUE;
        },
        web3Balance() {
            return this.web3.balance;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        stakesPrice() {
            return BigInt(this.stakeAmount) * BigInt(SINGLE_STAKE_TOKEN_VALUE);
        },
        isPriceWithinAllowance() {
            return this.$options.filters.removeDecimals(this.allowance) < this.stakesPrice;
        },
        isPriceWithinUserBalance() {
            return this.$options.filters.removeDecimals(this.web3Balance) < this.stakesPrice;
        },
        maxStakesAmountWithinBalance() {
            return Math.floor(this.$options.filters.removeDecimals(this.web3Balance) / SINGLE_STAKE_TOKEN_VALUE);
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
        height: 100%;
    }
}

.spacer {
    height: 20px;
}

.next-map-timer {
    color: #000 !important;
    left: -5px;
    top: -15px;
    position: absolute;
}

.header-text {
    margin: -15px auto 5px auto;
    width: 80%;
    text-align: center
}

#actions {
    width: 820px;
    margin: 0 auto 10px auto;
    padding: 20px 20px 0 20px;
    border-radius: 5px;
    background: #000;

    .input-approve-max-btn {
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 200px
    }

    .action-price {
        ::v-deep span {
            font-size: 25px;
        }
    }
}

.page-content {
    text-align: center;
    position: relative;
    width: 1000px
}

.map-layer {
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;
}

.pawn {
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;
}

.work-icon {
    width: auto;
    height: 16px;
}


.rewards {
    .reward-box {
        width: 80px;
        height: 120px;
        background: #925f31;
        display: inline-block;
        text-align: center;
        margin-right: 10px;
        border-radius: 5px;
        position: relative;

        &.inactive {
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
        }

        &.reward-box-wide {
            width: 100px;
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0 0 20px #fff;
        }

        img {
            margin: 6px auto 0 auto;
        }

        span {
            width: 100%;
            height: 20px;
            color: #000;
            bottom: 0;
            left: 0;
            position: absolute;
            background: #f2c086;
            border-radius: 0 0 5px 5px;
        }
    }
}

.stake-amount-total {
    z-index: 20;
    width: 209px;
    height: 143px;
    top: -0px;
    left: -15px;
    line-height: 14px;
    position: absolute;
    background: url('/img/staking-map/board.png');
    padding: 15px 0 0 15px;

    .work-points {
        display: inline-flex;
        align-items: center;
    }

    ::v-deep span {
        font-size: 25px;
    }
}

.map-selector-container {
    width: 800px;
}

.map-archive-btn {
    width: 150px;
    opacity: 0.5;
    box-shadow: 0 0 5px 0 #ccc !important;
    border-radius: 10px !important;

    &.selected {
        opacity: 1;
        box-shadow: 0 0 15px 0 #ccc !important;
    }

    img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
    }
}

.map-selector-btn {
    height: 260px !important;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px !important;
    padding-bottom: 226px !important;
    margin-bottom: -220px !important;
    opacity: 0.5;
    box-shadow: 0 0 5px 0 #ccc !important;
    border-radius: 10px 10px 0 0 !important;

    &.selected {
        padding-top: 16px !important;
        padding-bottom: 222px !important;
        opacity: 1;
        margin-bottom: -210px !important;
        margin-top: -10px !important;
        box-shadow: 0 0 15px 0 #ccc !important;
    }

    &.current-map {
        border: 2px solid var(--v-golden-base) !important;
    }

    img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
    }
}

@media only screen and (max-width: $sm) {
    .map-selector-container {
        width: 505px;
    }

    .map-selector-btn {

        &.selected {
            padding-top: 8px !important;
        }
    }

    .page-content {
        width: auto;
    }

    #actions {
        .input-approve-max-btn {
            left: auto;
            margin-right: 150px;
        }
    }

    .main-title {
        display: none;
    }
    .header-text {
        width: 95%;
        margin-top: 80px;
    }
    #actions {
        width: 100%;
        padding-bottom: 10px;
    }

    .stake-amount-total {
        display: none;
    }

    .spacer {
        height: 80px;
    }

    .next-map-timer {
        top: 80px;
        left: 50%;
        margin-left: -90px;
        z-index: 100;
    }
}

@media only screen and (max-width: 520px) {

    .map-selector-btn {
        padding: 6px 8px 187px !important;
        height: 220px !important;
        margin-bottom: -175px !important;
        width: 200px !important;

        &.selected {
            padding: 6px 8px 187px !important;
            margin-bottom: -165px !important;
            margin-top: -10px !important;
        }
    }

    .spacer {
        height: 100px;
    }

    .map-selector-container {
        margin: auto;
        width: 220px;
    }


    .next-map-timer {
        top: 130px;
    }
}
</style>
